<template>
  <div>
    <b-button variant="primary" class="mb-3" @click="addSubnet">
      افزودن زیرشبکه
    </b-button>
    <b-table :items="subnetList" :fields="fields">
      <template #cell(حذف)="ops">
        <b-icon
          @click="deleteEmit(ops.item.id)"
          class="w-6 h-6 hover:text-danger cursor-pointer block mx-2"
          icon="x-square"
        ></b-icon>
      </template>
    </b-table>
   
  </div>
</template>

<script>
export default {
  props : ['subnetList'],
  emits : ['deleteSubnet', 'subnetPrompt'],
  data () {
    return {
      fields: [
        { key: 'ip_range_cidr', label: 'رنج آی پی' },
        { key: 'created_at', label: 'تاریخ ساخت' },
        'حذف'
      ],
      deletePrompt : false
    }
  },
  methods: {
    addSubnet () {
      this.$emit('subnetPrompt')
    },
    deleteEmit (d) {
      this.$emit('deleteSubnet', d)
    }
  },
  created () {
    
    
  }
}

</script>
