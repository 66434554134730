<template>
  <div>
    <b-button variant="primary" class="mb-3" @click="addIP(ipList[0].vpc_id)">
      درخواست IP
    </b-button>
    <b-table :items="ipList" :fields="fields">
      <template #cell(برداشتنIP)="ops">
        <b-icon
          v-if="!ops.item.vm_id"
          @click="detachIp(ops.item.public_ip_id)"
          class="w-6 h-6 hover:text-danger cursor-pointer block mx-2"
          icon="x-square"
        ></b-icon>
      </template>
      <template #cell(vm_title)="vm">
        <router-link :to="`/apps/viracloud/${vm.item.vm_id}`" v-if="vm.value">{{ vm.value }}</router-link>
        <p class="text-danger" v-else>استفاده نشده</p>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  emits: ['reciveNewIp', 'deleteIp'],

  props: {
    ipList: {
      type: Array
    }
  },
  data () {
    return {
      fields: [
        { key: 'ip_address', label: 'آی پی جزیره' },
        { key: 'vpc_name', label: 'نام جزیره' },
        { key: 'vm_title', label: 'نام ویرا ابر' },
        'برداشتنIP'
      ]
    }
  },
  methods: {
    addIP (vpcId) {
      this.$emit('reciveNewIp', vpcId)
    },
    detachIp (public_ip_id) {
      this.$emit('deleteIp', public_ip_id)
    }
  },
  created () {
    // console.log(this.ipList)
  }
}
</script>
