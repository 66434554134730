<template>
  <div>
    <vx-card>
      <div>
         <div class="justify-between align-middle">
            <b-button
             color="primary"
             type="gradient"
             class="text-center bg-h-primary px-12 py-3 flex justify-center"
            @click="showSlider = !showSlider"
            >
            <b-icon icon="plus"></b-icon>
            ساخت جزیره جدید
            </b-button>
         </div>
        <!--***************** Sidebar **********************  -->
        <b-sidebar
          :visible="showSlider"
          id="sidebar-backdrop"
          backdrop
          right
          shadow
          z-index="99999"
        >
          <div class="ml-2">
            <h5>ساخت جزیره جدید</h5>
          </div>
          <vs-divider></vs-divider>
          <vx-card class="my-base p-2">
            <b-form @submit.prevent.stop class="" label-for="backdrop-variant">
              <div class="flex">
                 <label for="title" class="">عنوان جزیره :</label>
              </div>
              <b-form-input
                  v-validate="'required|alpha_dash|min:3'"
                  data-vv-validate-on="blur"
                v-model="VpcName"
                name="vpcTitle"
                class=""
                placeholder="jaziri-1"
              ></b-form-input>
               <span class="text-xs text-primary">نام جزیره نباید تکراری باشد<span>*</span></span>

              <span class="text-danger text-sm">{{ errors.first('vpcTitle') }}</span>
              <div class="flex mt-4">
                <label for="">ایجاد زیر شبکه برای این جزیره :</label>
                <vs-switch class="rounded-pill mx-3" v-model="subnetSwich" />
              </div>
              <div v-if="subnetSwich">
                <label class="mt-base">زیر شبکه :</label>
                <b-form-input
                  dir="ltr"
                  v-model="ipRange"
                  :state="validateIP"
                  class="w-50 my-3"
                  placeholder="ex : 192.168.0.0"
                ></b-form-input>
                <b-form-select
                  dir="ltr"
                  v-model="subnet"
                  class="w-25"
                  :options="slash"
                ></b-form-select>
              </div>
            </b-form>
          </vx-card>
          <vx-card>
            <b-button @click="createVPC" variant="info" :disabled="validateForm" class="w-full"
              >ایجاد جزیره</b-button
            >

          </vx-card>
        </b-sidebar>
      </div>

      <!--***************** Table **********************  -->
      <b-card v-if="vpcList.length > 0" class="overflow-hidde  mt-6" border-variant="dark">
        <b-table
            class="text-center align-middle"
            selectable
            head-variant="dark"
            responsive="md"
            :stacked="windowWidth < 800"
            :items="vpcList" :fields="fields"
            striped
        >
          <template #cell(created_at)="row">
            <vs-tooltip :text="row.item.created_at">
              <b-icon icon="calendar2-event" />
            </vs-tooltip>
          </template>
          <template #cell(عملیات)="row">
            <div class="flex justify-center">
              <vs-tooltip position="top" text="اضافه کردن آی پی به جزیره">
                <b-icon
                  @click="initAddIpPrompt(row.item.id)"
                  class="w-6 h-6 hover:text-primary cursor-pointer block mx-2"
                  icon="file-diff"
                ></b-icon>
              </vs-tooltip>
              <vs-tooltip position="top" text="اضافه کردن زیر شبکه">
                <b-icon
                  @click="initSubnetPrompt(row.item.id)"
                  class="w-6 h-6 hover:text-primary cursor-pointer mx-2 block"
                  icon="node-plus"
                ></b-icon>
              </vs-tooltip>
              <vs-tooltip position="top" text="حذف">
                <b-icon
                  @click="initDeletePrompt(row.item.id)"
                  class="w-6 h-6 hover:text-danger cursor-pointer mx-2 block"
                  icon="trash"
                ></b-icon>
              </vs-tooltip>
            <vs-tooltip position="top" text="مشاهده ساختار">
                <b-icon
                  @click="$router.push(`/apps/viraVpc/tree/${row.item.id}`)"
                  class="w-6 h-6 hover:text-success cursor-pointer mx-2 block"
                  icon="diagram3"
                ></b-icon>
              </vs-tooltip>
              <vs-tooltip position="top" text="مشاهده هزینه">
                <b-icon
                  @click="getCosts(row.item.id)"
                  class="w-6 h-6 hover:text-success cursor-pointer mx-2 block"
                  icon="cash"
                ></b-icon>
              </vs-tooltip>
            </div>
          </template>
        </b-table>
         <vs-pagination
           v-if="getVpcIpList.length > 0"
        class="center w-full"
        :total="countPage"
        v-model="pageNumber"
      ></vs-pagination>
      </b-card>
        <div class="flex flex-column justify-center items-center w-full" v-else>
          <img width="150" src="@/assets/images/elements/open-box.svg" alt="haio-logo">
          <h5>موردی جهت نمایش وجود ندارد</h5>
        </div>

    </vx-card>
    <!-- delete promp -->
    <div>
      <vs-popup :active.sync="deleteVpcPrompt" title="حذف جزیره">
        <div>
          <p  icon="new_releases"  color="warning">برای حذف جزیره باید ابتدا <span class="text-danger">VM ها</span>  و سپس <span class="text-danger">subnet های</span> جزیره را حذف کنید و سپس از این طریق اقدام به حذف جزیره نمایید</p>
          <vs-button
          @click="deleteVpc()"
            :icon-after="true"
            icon="delete"
            class="rounded float-right mt-3 w-25 "
            color="danger"
            >حذف</vs-button
          >
        </div>
      </vs-popup>
    </div>
    <!-- Add SubNet Prompt -->
    <div>
      <vs-popup :active.sync="subnetPrompt" title="">
        <div>
          <add-and-remove-subnet
          @deleteSubnet="deleteSubnet($event)"
          :subnetList="subnetList"
          :key="subnetList.id"
            @subnetPrompt="addSubnetPrompt = true"
          ></add-and-remove-subnet>
          <vs-popup :active.sync="addSubnetPrompt" title="افزودن زیرشبکه">
            <div>
              <div class="flex align-center justify-center">
                <b-form-select
                  v-model="subnet"
                  class="w-1/3"
                  :options="slash"
                ></b-form-select>
                <b-form-input
                  dir="ltr"
                  v-model="ipRange"
                  class="mx-1 flex-initial"
                  :state="validateIP"
                  placeholder="ex : 192.168.0.0"
                ></b-form-input>
                <br />
                <b-button
                  @click="createSubNet(vpcId)"
                  variant="info"
                  class="w-25"
                >
                  <b-icon icon="plus"></b-icon>
                  ساخت
                </b-button>
              </div>
            </div>
          </vs-popup>
        </div>
      </vs-popup>
    </div>
    <!-- buy new ip -->
    <div>
      <vs-popup
        accept-text="اضافه کردن"
        cancel-text="انصراف"
        @accept="reciveNewIp"
        :active.sync="attachIpPrompt"
        title="لیست IP"
      >
        <add-and-remove-ip
          @reciveNewIp="reciveNewIp($event)"
          @deleteIp="deleteVpcIp($event)"
          :ipList="iplist"
        ></add-and-remove-ip>
      </vs-popup>
    </div>
    <!-- price -->
     <div>
      <vs-popup
        :active.sync="pricePopUp"
        title="هزینه "
      >
      <vx-card
          class="mt-6 w-100"
        >
          هزینه ساعتی جزیره های شما با احتساب VM ها و Public IP موجود بصورت زیر می باشد.
          <ul>
            <li>هزینه ساعتی : <span class="text-primary">{{prices.hourly_cost }}</span> تومان</li>
            <li>هزینه ماهانه : <span class="text-primary">{{prices.monthly_cost}}</span> تومان</li>
          </ul>
        </vx-card>

      </vs-popup>
    </div>

  </div>
</template>

<script>
import AddAndRemoveIp from './AddAndRemoveIp.vue'
import AddAndRemoveSubnet from './AddAndRemoveSubnet.vue'
import { Validator } from 'vee-validate'
const dict = {
  custom: {
    vpcTitle: {
      required: ' لطفا عنوان جزیره را وارد کنید',
      alpha_dash: 'قسمت نام می تواند شامل حروف لاتین   , عدد   -  باشد '
    }

  }
}


Validator.localize('en', dict)
export default {
  components: {
    AddAndRemoveIp,
    AddAndRemoveSubnet
  },
  data () {
    return {
      prices : {},
      countPage : 0,
      pageNumber : 1,
      subnetList   : [],
      attachIpPrompt: false,
      vpcId: '',
      subnetPrompt: false,
      addSubnetPrompt: false,
      deleteVpcPrompt : false,
      pricePopUp : false,
      iplist: [],
      addSubnet: '',
      ipRange: '192.168.5.0',
      subnet: '24',
      vpcList: [],
      VpcName: '',
      subnetSwich: false,
      showSlider: false,
      fields: [
        { key: 'vpc_name', label: ' جزیره' },
        { key: 'vpc_sequence', label: 'شماره' },

        { key: 'created_at', label: 'تاریخ  ساخت' },
        'عملیات'
      ],
      slash: [
        { value: 24, text: '/24' },
        { value: 25, text: '/25' },
        { value: 26, text: '/26' },
        { value: 27, text: '/27' },
        { value: 28, text: '/28' },
        { value: 29, text: '/29' },
        { value: 30, text: '/30' }
      ]
    }
  },
  watch : {
    pageNumber () {
      this.getVpc()
    }
  },
  computed: {
    validateForm () {
      return this.errors.any()
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    validateIP () {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          this.ipRange
        )
      ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    initDeletePrompt (id) {
      this.vpcId = id
      this.deleteVpcPrompt = true
    },
    initAddIpPrompt (id) {
      this.vpcId = id
      this.getVpcIpList()
      this.attachIpPrompt = true
    },
    initSubnetPrompt (id) {
      this.vpcId = id
      this.subnetPrompt = true
      this.getSubnetList()
    },
    createVPC () {
      this.$http
        .post('/vira/cloud/vpc/create', { vpc_name: this.VpcName })
        .then(result => {
          const vpcId = result.data.params.vpc_id
          this.$vs.notify({
            text: result.data.message,
            color: 'success'
          })
          if (this.subnetSwich) {
            this.createSubNet(vpcId)
          }
          this.getVpc()
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        })
        .finally(() => {
          this.VpcName = ''
          this.showSlider = false
        })
    },
    deleteVpc () {
      this.$http.post('/vira/cloud/vpc/delete', {vpc_id : this.vpcId}).then((res) => {
        this.$vs.notify({
          text : res.data.message,
          color : 'success'
        })
        this.vpcList = this.vpcList.filter(el => el.id !== this.vpcId)
        this.deleteVpcPrompt = false

      }).catch((err) => {
        this.$vs.notify({
          text : err.response.data.message,
          color : 'danger'
        })
      })
    },
    getVpc () {
      this.$http
        .get(`/vira/cloud/vpc/list?page=${this.pageNumber}`)
        .then(result => {
          // console.log(result.data)
          this.vpcList = result.data.params.data
          this.countPage = result.data.params.last_page
        })
        .catch(err => {
          console.log(err)
        })
    },
    createSubNet (vpcId) {
      this.$http
        .post('/vira/cloud/subnet/create', {
          vpc_id: vpcId,
          ip_range: this.ipRange,
          subnet: this.subnet
        })
        .then(result => {
          this.$vs.notify({
            text: result.data.message,
            color: 'success'
          })
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        }).finally(() => {
          this.addSubnetPrompt = false
          this.getSubnetList()
        })
    },
    getVpcIpList () {
      this.$http
        .get(`/vira/cloud/publicip/list?vpc_id=${this.vpcId}`)
        .then(res => {
          this.iplist = res.data.params.data
        })
        .catch(() => {})
    },
    reciveNewIp () {
      this.$http
        .post('/vira/cloud/publicip/new', { vpc_id: this.vpcId })
        .then(res => {
          this.$vs.notify({
            text: res.data.message,
            color: 'success'
          })
          // renew vpc ip list after recive new ip
          this.getVpcIpList()
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        })
    },
    getSubnetList () {
      this.$http.get(`/vira/cloud/subnet/list?vpc_id=${this.vpcId}`).then((res) => {
        this.subnetList = res.data.params.data
      }).catch((err) => {
        console.log(err)
      })
    },
    deleteVpcIp (id) {
      this.$http
        .post('/vira/cloud/publicip/delete', { public_ip_id: id })
        .then(res => {
          this.$vs.notify({
            text: res.data.message,
            color: 'success'
          })


        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        }).finally(() => {
          this.getVpcIpList()
        })
    },
    deleteSubnet (id) {

      this.$http
        .post('/vira/cloud/subnet/delete', { subnet_id: id })
        .then(res => {
          this.$vs.notify({
            text: res.data.message,
            color: 'success'
          })
          // renew vpc ip list after delete it
          this.getVpcIpList()
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        }).finally(() => {
          this.getSubnetList()
        })
    },
    getCosts (id) {
      this.$http.get(`/vira/cloud/vpc/cost?vpc_id=${id}`).then((result) => {
        this.prices = result.data.params.data[0]
        this.pricePopUp = true
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created () {
    this.getVpc()
    // this.getCosts()
  }

}
</script>
